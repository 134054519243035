import classnames from "classnames";
import { section_key } from "features/AdminPanel/constants/AdminPanel.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { updateUrlWithParam } from "features/Common/utils/url.utils";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./SideBarStyles.module.css";
import { SectionMenuItem } from "./modules/SectionMenuItem/SectionMenuItem";
import { SideBarFooterCard } from "./modules/SideBarFooterCard/SideBarFooterCard";
import { findSectionByPath } from "./utils/SideBar.utils";

export const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const [selectedSectionId, setSelectedSectionId] = useState("");

  const adminPanelLayoutConfig = useAdminPanelStore(
    (state) => state.adminPanelLayoutConfig
  );

  const { sideBarConfig = [] } = adminPanelLayoutConfig;

  useEffect(() => {
    const { subSection } = findSectionByPath({
      config: sideBarConfig,
      path: location.pathname,
    });
    if (subSection) {
      setSelectedSectionId(subSection.id);
    }
  }, [location.pathname, sideBarConfig]);

  const handleSectionClick = ({ subSection = {} }) => {
    const { id, path, section_key: sectionKeyValue } = subSection;
    setSelectedSectionId(id);
    if (path) {
      history.replace(
        sectionKeyValue
          ? updateUrlWithParam({
              path,
              key: section_key,
              value: sectionKeyValue,
            })
          : path
      );
    }
  };

  return (
    <div
      className={classnames(
        styles.menuWrapper,
        "tw-bg-[#fafafa] max-md:tw-bg-[#F3F2F8] max-md:!tw-pt-0"
      )}
    >
      <div className={styles.sectionWrapper}>
        {sideBarConfig.map((section) => (
          <div key={section.id} className={styles.sectionContainer}>
            <div
              className={classnames(
                styles.sectionTitle,
                "max-md:!tw-text-[#8F87B4] tw-mt-6 max-md:tw-mb-3 tw-mb-[10px]"
              )}
            >
              {section.section_title}
            </div>

            <div className="max-md:tw-rounded-lg tw-overflow-hidden max-md:tw-bg-white max-md:tw-mx-4">
              {section.sub_section?.map((subSection, index) => {
                const isActive = subSection.id === selectedSectionId;
                return (
                  <SectionMenuItem
                    key={subSection.id}
                    subSection={subSection}
                    handleSectionClick={handleSectionClick}
                    isActive={isActive}
                    isLastSection={index < section.sub_section.length - 1}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <SideBarFooterCard />
      </div>
    </div>
  );
};
