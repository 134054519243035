import React from "react";
import styles from "./SectionMenuItemStyles.module.css";
import ExlyImage from "common/Components/ExlyImage";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import classnames from "classnames";

export const SectionMenuItem = ({
  handleSectionClick = () => {},
  subSection,
  isActive,
  isLastSection,
}) => {
  return (
    <div
      key={subSection.id}
      className={classnames(
        styles.subSectionWrapper,
        isActive && styles.activeSubSectionWrapper,
        "md:tw-py-0 md:tw-px-4 md:tw-h-[38px] max-md:tw-pl-4"
      )}
      style={{
        "--primary-color": subSection.primary_color,
        "--bg-color": subSection.secondary_color,
      }}
      onClick={() => handleSectionClick({ subSection })}
    >
      <ExlyImage
        src={
          isActive
            ? subSection.side_menu_active_icon
            : subSection.side_menu_icon
        }
        fetchWidth={60}
        className={styles.sideMenuIcon}
      />
      <div
        className={classnames(
          styles.subSectionTitle,
          isActive && styles.activeSubSectionTitle,
          "max-md:tw-py-[10px] max-md:tw-flex max-md:tw-flex-1 max-md:tw-justify-between max-md:tw-items-center",
          isLastSection &&
            "max-md:tw-border-solid max-md:tw-border-0 max-md:tw-border-b-[0.5px] max-md:tw-border-b-[#C6C6C8]"
        )}
      >
        <div>{subSection.sub_section_title}</div>
        <ChevronRightRoundedIcon className="!tw-text-[#BEBEBE] !tw-mr-2 md:!tw-hidden" />
      </div>
    </div>
  );
};
