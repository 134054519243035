import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { REQUEST_VERIFICATION_END_POINT } from "features/AdminPanel/constants/AdminPanel.api";

export const requestVerification = (payload) => {
  return dataProvider.custom_request(
    REQUEST_VERIFICATION_END_POINT,
    apiMethods.POST,
    payload
  );
};
