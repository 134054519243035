export const findSectionByPath = ({ config, path }) => {
  for (const section of config) {
    for (const subSection of section.sub_section) {
      if (subSection.path === path) {
        return {
          subSection,
        };
      }
    }
  }
  return {};
};
