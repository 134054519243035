import {
  ADMIN_PANEL_SECTION_CONFIG_FE_KEYS,
  ADMIN_PANEL_SECTION_CONFIG_FE_STATUS,
} from "features/AdminPanel/constants/AdminPanel.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { updateAdminPanelSettings } from "features/AdminPanel/utils/AdminPanel.utils";
import { STATUS_VISIBLE_TIME } from "features/ManageOfferings/modules/SavingStatus/constants/SavingStatus.constants";
import { useRef } from "react";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";

export const useAccountSectionUnlocking = () => {
  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const { notify } = useNotifications();
  const timerRef = useRef(null);

  const handleSectionChange = async ({
    payload = {},
    endPoint,
    ...restParams
  }) => {
    if (!payload.feature_key) return;

    if (timerRef.current) clearTimeout(timerRef.current);

    try {
      setSectionConfig({
        [payload.feature_key]: {
          ...sectionConfig[payload.feature_key],
          [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.FE_METADATA]: {
            [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.UPDATION_STATUS]: {
              [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.STATUS]:
                ADMIN_PANEL_SECTION_CONFIG_FE_STATUS.SAVING, // show saving status on ui
            },
          },
        },
      });
      const response = await updateAdminPanelSettings({
        endPoint,
        payload,
      });
      if (isRequestSuccessful(response?.status))
        setSectionConfig({
          [payload.feature_key]: {
            ...restParams,
            value: payload.feature_value,
            [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.FE_METADATA]: {
              [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.UPDATION_STATUS]: {
                [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.STATUS]:
                  ADMIN_PANEL_SECTION_CONFIG_FE_STATUS.SAVED, // show saved status on ui
              },
            },
          },
        });

      // hide saved status after STATUS_VISIBLE_TIME
      timerRef.current = setTimeout(() => {
        setSectionConfig({
          [payload.feature_key]: {
            ...restParams,
            value: payload.feature_value,
            [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.FE_METADATA]: undefined,
          },
        });
      }, STATUS_VISIBLE_TIME);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;

      notify(errorMessage, notification_color_keys.error);

      setSectionConfig({
        [payload.feature_key]: {
          ...sectionConfig[payload.feature_key],
          [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.FE_METADATA]: {
            [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.UPDATION_STATUS]: {
              [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.STATUS]:
                ADMIN_PANEL_SECTION_CONFIG_FE_STATUS.ERROR, // show error status on ui
            },
          },
        },
      });

      // hide error status after STATUS_VISIBLE_TIME
      timerRef.current = setTimeout(() => {
        setSectionConfig({
          [payload.feature_key]: {
            ...sectionConfig[payload.feature_key],
            [ADMIN_PANEL_SECTION_CONFIG_FE_KEYS.FE_METADATA]: undefined,
          },
        });
      }, STATUS_VISIBLE_TIME);

      logError({
        error,
        occuredAt: "Error updating section toggle",
        when: "calling handleSectionChange",
      });
    }
  };

  return {
    handleSectionChange,
  };
};
