import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { requestVerification } from "../../utils/RequestVerification.utils";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { REQUEST_VERIFICATION_STRIPE_CONFIG } from "../../constants/RequestVerificationStripe.constants";

export const useRequestVerificationModal = (config = {}) => {
  const { notify } = useNotifications();
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[config.key];

  const requestVerificationKey = useAdminPanelStore(
    (state) => state.requestVerificationKey
  );

  const setRequestVerificationKey = useAdminPanelStore(
    (state) => state.setRequestVerificationKey
  );

  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );

  const requestVerificationConfig =
    REQUEST_VERIFICATION_STRIPE_CONFIG[subSectionConfig?.status] || {};

  const closeRequestVerificationModal = () => {
    setRequestVerificationKey(null);
  };

  const openRequestVerificationModal = () => {
    setRequestVerificationKey(config.key);
  };

  const handleRequestVerification = async (key) => {
    if (!key) return;

    const payload = {
      feature_key: key,
    };

    try {
      const response = await requestVerification(payload);
      if (isRequestSuccessful(response?.status)) {
        setSectionConfig({
          ...sectionConfig,
          [key]: {
            ...(sectionConfig[key] || {}),
            status: FEATURE_STATUS.ACCESS_REQUESTED,
          },
        });

        closeRequestVerificationModal();
      }
    } catch (error) {
      notify(error.message, notification_color_keys.error);
      logError({
        error,
        occuredAt: "Error calling verification status update",
        when: "calling handleRequestVerification",
      });
    }
  };
  return {
    requestVerificationConfig,
    handleRequestVerification,
    openRequestVerificationModal,
    closeRequestVerificationModal,
    requestVerificationKey,
  };
};
