import { notification_color_keys, useNotifications } from "utils/hooks";
import { requestVerification } from "../../SubSectionWrapper/modules/RequestVerificationStripe/utils/RequestVerification.utils";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import { useAccountStore } from "features/Account/store/useAccountStore";
import { PAYMENT_OPTION_KEYS } from "../../PaymentOptions/constants/PaymentOptions.constants";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";

export const useLutModal = () => {
  const { notify } = useNotifications();

  const isLutModalOpen = useAccountStore((state) => state.isLutModalOpen);

  const setIsLutModalOpen = useAccountStore((state) => state.setIsLutModalOpen);
  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);

  const openLutModal = () => {
    setIsLutModalOpen(true);
  };

  const closeLutModal = () => {
    setIsLutModalOpen(false);
  };

  const handleSaveUploadedFiles = async (formData) => {
    const key = PAYMENT_OPTION_KEYS.LUT;
    const payload = {
      feature_key: key,
      ...formData,
    };

    try {
      const response = await requestVerification(payload);
      if (isRequestSuccessful(response?.status)) {
        setSectionConfig({
          ...sectionConfig,
          [key]: {
            ...(sectionConfig[key] || {}),
            status: FEATURE_STATUS.ACCESS_REQUESTED,
          },
        });
        closeLutModal();
      }
    } catch (error) {
      notify(error.message, notification_color_keys.error);
      logError({
        error,
        occuredAt: "Error calling verification status update",
        when: "calling handleSaveUploadedFiles",
      });
    }
  };

  return {
    isLutModalOpen,
    openLutModal,
    closeLutModal,
    handleSaveUploadedFiles,
  };
};
