import { FEATURE_STATUS } from "features/Account/Account.constants";
import { getOptimisedS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { MailLink } from "features/Common/modules/MailLink/MailLink";
import React from "react";

export const REQUEST_VERIFICATION_STRIPE_CONFIG = {
  // to request
  [FEATURE_STATUS.ACCESS_LOCKED]: {
    text: {
      title:
        "Standard verification required to ensure your payments are secure.",
      color: "#033c4a",
    },
    backgroundColor: "#eef8ff",
    btn: { type: "submit", color: "#0f619c", btnText: "Request verification" },
  },
  // requested
  [FEATURE_STATUS.ACCESS_REQUESTED]: {
    text: {
      title: "We’ll update you as soon as the verification is complete.",
      color: "#473905",
    },
    backgroundColor: "#FFF7DA",
    btn: {
      color: "#473905",
      btnText: "Verification in progress...",
      className: "inProgressBtn",
      icon: getOptimisedS3AssetCDNUrl("Account/in_progress.png"),
    },
  },
  // failed
  [FEATURE_STATUS.ACCESS_DENIED]: {
    text: {
      title: (
        <>
          Verification has failed. Retry or contact <MailLink /> for any
          queries.
        </>
      ),
      color: "rgba(39, 37, 34, 0.80)",
    },
    backgroundColor: "#F2F2F2",
    btn: {
      type: "submit",
      color: "rgba(39, 37, 34, 0.85)",
      btnText: "Retry verification",
    },
  },
};
