import { logError } from "utils/error";
import { getSectionConfig } from "./Account.utils";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";

export const useSectionConfig = () => {
  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );

  const sectionConfigLoading = useAdminPanelStore(
    (state) => state.sectionConfigLoading
  );

  const setSectionConfigLoading = useAdminPanelStore(
    (state) => state.setSectionConfigLoading
  );
  const fetchSection = async ({ key, featureKey }) => {
    if (!key || !featureKey) return;
    if (sectionConfigLoading) return;
    try {
      setSectionConfigLoading(true);
      const response = await getSectionConfig({ key, featureKey });
      setSectionConfig(response.data || []);
    } catch (error) {
      logError({
        error,
        occuredAt: "Error fetching section config:",
        when: "calling fetchSection",
      });
    } finally {
      setSectionConfigLoading(false);
    }
  };

  return { fetchSection };
};
