// aps
export const FETCH_FEATURE_CREDITS_API = "business/credits/details";
export const FETCH_FEATURE_LIST_API = "business/credits/feature/consumables";
export const FETCH_CREATOR_ACTIVE_PLAN_API = "business/combo/details";
export const FETCH_SUBSCRIPTION_TRANSACTION_API = "business/combo/plans";
export const UPDATE_CART_API = "business/credits/cart/update";
export const FETCH_CART_API = "business/credits/cart/items";
export const INITIATE_PAYMENT_API = "business/credits/payment/initiate";
export const FETCH_ORDER_STATUS_API = "business/credits/payment/status";
export const FETCH_FEATURE_LEDGER_API = "business/credits/ledger";
export const FETCH_LEDGER_TRIGGERS_API = "business/credits/ledger/trigger";
export const FETCH_HIGHLIGHTS_API = "business/credits/highlight";
export const FETCH_FEATURE_PRICING_DETAILS_API =
  "business/credits/pricing/details";
export const FETCH_FEATURE_ACCESSIBILITY_API =
  "business/credits/accessiblility";

export const ACCOUNT_FEATURE_KEY = "myaccount";
// Feature keys
export const FEATURE_KEYS = {
  whitelabel: "whitelabel",
};

export const UPFRONT_PLAN_TYPES = {
  PRO: 1, // pro
  PREMIUM: 2, // premium
};

export const PAYOUT_CYCLE_TYPES = {
  INSTANT: 1,
  MONTHLY: 2,
  CUSTOM: 3,
};

export const OTP_PURPOSE = {
  details_verifications: 2,
  creater_detail_updation: 1,
  creator_email_updation: 3,
  creator_phone_number_updation: 4,
};

export const EXLY_SUPPORT_EMAIL = "support@myscoot.in";

export const FEATURE_STATUS = {
  ACCESS_LOCKED: 1,
  ACCESS_REQUESTED: 2,
  ACCESS_GRANTED: 3,
  ACCESS_DENIED: 4,
  PLAN_LOCKED: 5,
  HIDDEN: 6,
  UPLOAD_DOCUMENT: 7,
};
