import ExlySwitch from "common/form/ExlySwitch";
import React from "react";

export const SectionUnlockingSwitch = ({ value, onChange, disabled }) => {
  return (
    <ExlySwitch
      noIcon
      size="large"
      color="success"
      input={{
        value,
        onChange,
      }}
      disabled={disabled}
    />
  );
};
