import { useAccountStore } from "features/Account/store/useAccountStore";

export const useSignUpBonusActiveRefundsModal = () => {
  const isSignUpBonusRefundsModal = useAccountStore(
    (state) => state.isSignUpBonusRefundsModal
  );
  const setisSignUpBonusRefundsModal = useAccountStore(
    (state) => state.setIsSignUpBonusRefundsModal
  );

  const closeSignUpBonusRefundsModal = () => {
    setisSignUpBonusRefundsModal(false);
  };

  const openSignUpBonusRefundsModal = () => {
    setisSignUpBonusRefundsModal(true);
  };

  return {
    closeSignUpBonusRefundsModal,
    openSignUpBonusRefundsModal,
    isSignUpBonusRefundsModal,
  };
};
