export const section_key = "section_key";
export const ADMIN_PANEL_SETTINGS_TITLE = "Exit Settings";

// only used for front-end handling
// please keep the keys in capital letters to differentiate from the backend keys
export const ADMIN_PANEL_SECTION_CONFIG_FE_KEYS = {
  FE_METADATA: "FE_METADATA",
  UPDATION_STATUS: "UPDATION_STATUS",
  STATUS: "STATUS",
  ERROR: "ERROR",
};

export const ADMIN_PANEL_SECTION_CONFIG_FE_STATUS = {
  SAVING: 1,
  SAVED: 2,
  ERROR: 3,
  IDLE: 4,
};

export const ADMIN_ROUTE_PARAMS = {
  listing_uuid: "listing_uuid",
  offeringUuid: "offeringUuid",
};
