import React, { useEffect } from "react";
import { useSectionConfig } from "features/Account/utils/useSectionConfig";

/**
 * SectionConfigWrapper Component
 *
 * This component fetches and initializes a section's configuration when it mounts.
 * It ensures the required section data is available before rendering its children.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.sectionKey - Unique key identifying the section to fetch.
 * @param {string} props.featureKey - Key representing the feature this section belongs to.
 * @param {React.ReactNode} props.children - React elements to be wrapped inside the component.
 * @returns {JSX.Element} Wrapped children components after fetching the section config.
 */
export const SectionConfigWrapper = ({ sectionKey, featureKey, children }) => {
  const { fetchSection } = useSectionConfig();

  useEffect(() => {
    fetchSection({
      featureKey,
      key: sectionKey,
    });
  }, [sectionKey, featureKey]);

  return <>{children}</>;
};
