import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import styles from "./DocumentUploadedNote.module.css";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { FEATURE_STATUS } from "features/Account/Account.constants";

export const DocumentUploadedNote = ({ text }) => {
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[PAYMENT_OPTION_KEYS.LUT] || {};
  const { status } = subSectionConfig;
  const documentsUploaded = status === FEATURE_STATUS.ACCESS_REQUESTED;

  if (!documentsUploaded) return <></>;
  return (
    <div className={styles.disclaimer}>
      <InfoOutlined className={styles.infoIcon} />
      <div className={styles.note}>{text}</div>
    </div>
  );
};
