import React from "react";
import styles from "./SectionEnabledChipStyles.module.css";

export const SectionEnabledChip = () => {
  return (
    <div className={styles.chipWrapper}>
      <div className={styles.text}>Enabled</div>
    </div>
  );
};
