import React from "react";
import { getProfilePicture } from "../utils/SideBarFooterCard.utils";
import ExlyImage from "common/Components/ExlyImage";
import { getAuthData } from "utils/AuthUtil";
import styles from "./SideBarFooterCardStyles.module.css";
import classnames from "classnames";
import { ROUTE_LAYOUT_CONFIG } from "features/UILayout/modules/Menu/Layouts/constants/LayoutsConfig.constants";
import { matchPath, useLocation } from "react-router-dom";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import { ADMIN_ROUTE_PARAMS } from "features/AdminPanel/constants/AdminPanel.constants";
import { getListingShareUrl } from "utils/urlUtils";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

export const SideBarFooterCard = () => {
  const auth = getAuthData();
  const profilePicture = getProfilePicture();
  const location = useLocation();
  const queryParams = getRAUrlParams();
  const paramsListingUuid =
    queryParams?.get(ADMIN_ROUTE_PARAMS.listing_uuid) ||
    queryParams?.get(ADMIN_ROUTE_PARAMS.offeringUuid);
  let params;
  Object.keys(ROUTE_LAYOUT_CONFIG).some((routeId) => {
    const match = matchPath(location.pathname, {
      path: routeId, // Match against the route ID in the config
      exact: true,
    });

    if (match) {
      params = match.params;
      return true;
    }
  });
  const listingUuid =
    paramsListingUuid ||
    params?.[ADMIN_ROUTE_PARAMS.listing_uuid] ||
    params?.[ADMIN_ROUTE_PARAMS.offeringUuid];
  const webpageUrl = listingUuid
    ? getListingShareUrl({ uuid: listingUuid })
    : getCreatorHostsiteURL()?.url;

  return (
    <div className={styles.cardWrapper}>
      <ExlyImage
        className={styles.profileImg}
        src={profilePicture}
        alt="display_image"
      />
      <div className={styles.nameWrapper}>
        <div className={classnames(styles.name, "ellipsis")}>
          {auth.display_name}
        </div>
        <a
          className={classnames(styles.link, "ellipsis")}
          target="_blank"
          rel="noreferrer"
          href={webpageUrl}
        >
          {webpageUrl}
        </a>
      </div>
    </div>
  );
};
