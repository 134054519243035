import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { useEffect } from "react";

export const useAdminPanelLayoutConfig = ({ config }) => {
  const setAdminPanelLayoutConfig = useAdminPanelStore(
    (state) => state.setAdminPanelLayoutConfig
  );

  useEffect(() => {
    setAdminPanelLayoutConfig(config);
  }, [config]);
};
