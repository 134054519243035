import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";

const MyAccount = React.lazy(() =>
  import("features/Account/modules/Settings/Settings").then((module) => ({
    default: module.Settings,
  }))
);
const ProfileDetails = React.lazy(() =>
  import("features/Account/modules/ProfileDetails/ProfileDetails").then(
    (module) => ({
      default: module.ProfileDetails,
    })
  )
);

const Features = React.lazy(() =>
  import("features/Account/modules/Features/Features").then((module) => ({
    default: module.Features,
  }))
);

const PaymentOptions = React.lazy(() =>
  import("features/Account/modules/PaymentOptions/PaymentOptions").then(
    (module) => ({
      default: module.PaymentOptions,
    })
  )
);

const Communications = React.lazy(() =>
  import("features/Account/modules/Communications/Communications").then(
    (module) => ({
      default: module.Communications,
    })
  )
);

const ContactForm = React.lazy(() =>
  import(
    "features/Account/modules/Communications/modules/ContactForm/ContactForm"
  ).then((module) => ({
    default: module.ContactForm,
  }))
);

export const ACCOUNT_ROUTE_KEYS = {
  MY_ACCOUNT: "MY_ACCOUNT",
  PROFILE_DETAILS: "PROFILE_DETAILS",
  FEATURES: "FEATURES",
  PAYMENT_OPTIONS: "PAYMENT_OPTIONS",
  COMMUNICATIONS: "COMMUNICATIONS",
  EDIT_CONTACT_FORM: "EDIT_CONTACT_FORM",
};

export const ACCOUNT_ROUTE_IDS = {
  [ACCOUNT_ROUTE_KEYS.MY_ACCOUNT]: "/myAccount",
  [ACCOUNT_ROUTE_KEYS.PROFILE_DETAILS]: "/profile-details",
  [ACCOUNT_ROUTE_KEYS.FEATURES]: "/features",
  [ACCOUNT_ROUTE_KEYS.PAYMENT_OPTIONS]: "/payments",
  [ACCOUNT_ROUTE_KEYS.COMMUNICATIONS]: "/communications",
  [ACCOUNT_ROUTE_KEYS.EDIT_CONTACT_FORM]: "/edit-contact-form",
};

export const ACCOUNT_CUSTOM_ROUTES = [
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.MY_ACCOUNT]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.MY_ACCOUNT]}
    render={(props) => <LazyLoadRoutes LazyElement={MyAccount} props={props} />}
  />,
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PROFILE_DETAILS]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PROFILE_DETAILS]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ProfileDetails} props={props} />
    )}
  />,
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.FEATURES]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.FEATURES]}
    render={(props) => <LazyLoadRoutes LazyElement={Features} props={props} />}
  />,
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PAYMENT_OPTIONS]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.PAYMENT_OPTIONS]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={PaymentOptions} props={props} />
    )}
  />,
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.COMMUNICATIONS]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.COMMUNICATIONS]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={Communications} props={props} />
    )}
  />,
  <Route
    exact
    key={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.EDIT_CONTACT_FORM]}
    path={ACCOUNT_ROUTE_IDS[ACCOUNT_ROUTE_KEYS.EDIT_CONTACT_FORM]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ContactForm} props={props} />
    )}
  />,
];
