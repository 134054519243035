import React from "react";
import { format } from "date-fns";
import { PAYMENT_OPTION_KEYS } from "../PaymentOptions/constants/PaymentOptions.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { SectionEnabledChip } from "features/AdminPanel/SectionEnabledChip/SectionEnabledChip";
import { SectionUnlockingSwitch } from "features/AdminPanel/modules/SectionUnlockingSwitch/SectionUnlockingSwitch";
import { useAccountSectionUnlocking } from "features/Account/utils/useAccountSectionChange";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";
import { SignUpBonusActiveRefundsModal } from "../SignUpBonusActiveRefundsModal/SignUpBonusActiveRefundsModal";
import { useSignUpBonusActiveRefundsModal } from "../SignUpBonusActiveRefundsModal/utils/useSignUpBonusActiveRefundsModal";
import { MONTH_SHORT_DAY_YEAR_TIME_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";
import { getSectionStates } from "features/AdminPanel/utils/AdminPanel.utils";
import styles from "./RefundsSubSection.module.css";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";

export const RefundsSubSection = () => {
  const key = PAYMENT_OPTION_KEYS.CUSTOMER_REFUND;
  const whatsNextData = getWhatsNextInfo();

  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[key] || {};
  const { value, status, end_datetime: planExpiryDate } = subSectionConfig;
  const { disableSwitch } = getSectionStates({
    status,
  });
  const { handleSectionChange } = useAccountSectionUnlocking();

  const { openSignUpBonusRefundsModal, isSignUpBonusRefundsModal } =
    useSignUpBonusActiveRefundsModal();

  const handleRefundChange = () => {
    if (whatsNextData[whats_next_keys.has_offer_enabled.key]) {
      openSignUpBonusRefundsModal(true);
    } else {
      const payload = {
        feature_key: key,
        feature_value: !value,
      };
      handleSectionChange({
        ...subSectionConfig,
        payload,
      });
    }
  };
  return value ? (
    <div className={styles.wrapper}>
      <SectionEnabledChip planExpiryDate={planExpiryDate} />
      <div className={styles.date}>
        Until&nbsp;
        {format(new Date(planExpiryDate), MONTH_SHORT_DAY_YEAR_TIME_FORMAT)} IST
      </div>
    </div>
  ) : (
    <>
      <SectionUnlockingSwitch
        value={value}
        onChange={handleRefundChange}
        disabled={disableSwitch}
      />
      <LazyLoadWrapper loadingCondition={isSignUpBonusRefundsModal}>
        <SignUpBonusActiveRefundsModal />
      </LazyLoadWrapper>
    </>
  );
};
