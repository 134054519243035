import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React, { useEffect } from "react";
import styles from "./RequestVerificationModalStyles.module.css";
import { useRequestVerificationModal } from "../utils/useRequestVerificationModal";

export const RequestVerificationModal = withComponentLibraryTheme(() => {
  const {
    handleRequestVerification,
    closeRequestVerificationModal,
    requestVerificationKey,
  } = useRequestVerificationModal();

  useEffect(() => {
    return () => closeRequestVerificationModal();
  }, []);
  return (
    <ExlyModal
      title="Initiate verification request"
      header="Initiate verification request"
      primaryBtnText="Submit Request"
      onPrimaryBtnClick={() =>
        handleRequestVerification(requestVerificationKey)
      }
      open={!!requestVerificationKey}
      onClose={closeRequestVerificationModal}
      padded={false}
      showSecondaryBtn={true}
      modal_props={{
        modalPaperClassName: styles.modalPaper,
        secondaryBtnClassName: styles.secondaryBtn,
        footerClassName: styles.footer,
      }}
      mobile_modal_props={{
        secondaryAction: {
          label: "Cancel",
          onClick: closeRequestVerificationModal,
          className: styles.secondaryBtn,
        },
      }}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          To ensure secure and compliance transactions for your customers, our
          team needs to verify a few details. This process may take up to 7
          days. We will notify you as soon as the verification is complete.
        </div>
      </div>
    </ExlyModal>
  );
});
