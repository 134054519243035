import { create } from "zustand";

export const useAccountStore = create((set) => ({
  isLutModalOpen: false, // LUT modal open/close state
  setIsLutModalOpen: (value) => set({ isLutModalOpen: value }),
  isLeadContactFormSettingsModalOpen: false, // lead collection form settings modal open/close state
  setIsLeadContactFormSettingsModalOpen: (value) =>
    set({ isLeadContactFormSettingsModalOpen: value }),
  leadContactFormInitialValuesLoading: false,
  setLeadContactFormInitialValuesLoading: (value) =>
    set({ leadContactFormInitialValuesLoading: value }),
  leadContactFormInitialValues: {}, // lead collection form initial valaues state
  setLeadContactFormInitialValues: (value) =>
    set({ leadContactFormInitialValues: value }),
  isExitIntentSettingsModalOpen: false, // exit intent modal open/close state
  setIsExitIntentSettingsModalOpen: (value) =>
    set({ isExitIntentSettingsModalOpen: value }),
  exitIntentInitialValues: {}, // exit intent initial values
  exitIntentInitialValuesLoading: false,
  setExitIntentInitialValuesLoading: (value) =>
    set({ exitIntentInitialValuesLoading: value }),
  setExitIntentInitialValues: (value) =>
    set({ exitIntentInitialValues: value }),
  contactFormQuestionsList: [], // lead contact form questions list state
  setContactFormQuestionsList: (value) =>
    set({ contactFormQuestionsList: value }),
  contactFormQuestionsListLoader: false, // loader for lead contact form questions list state
  setContactFormQuestionsListLoading: (value) =>
    set({ contactFormQuestionsListLoading: value }),
  isAddQuestionModalOpen: false, // lead contact form add question modal open/close state
  setIsAddQuestionModalOpen: (value) => set({ isAddQuestionModalOpen: value }),
  isEditQuestionModalOpen: false, // lead contact form edit/open or close state
  setIsEditQuestionModalOpen: (value) =>
    set({ isEditQuestionModalOpen: value }),
  questionInitialValues: false, // initial values for lead contact form questions
  setQuestionInitialValues: (value) => set({ questionInitialValues: value }),
  isSignUpBonusRefundsModal: false, // request verification modal state for customer refunds when signup bonus is active
  setIsSignUpBonusRefundsModal: (value) =>
    set({ isSignUpBonusRefundsModal: value }),
}));
