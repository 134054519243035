import { ArrowBack } from "@material-ui/icons";
import classnames from "classnames";
import { useStickyHeader } from "features/AdminPanel/utils/useStickyHeader";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./SectionHeadingStyles.module.css";

export const SectionHeading = ({
  heading,
  backUrl,
  showBackIcon = false,
  classes = {},
  headerRight = <></>,
}) => {
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();
  const onBackClick = () => {
    if (backUrl) history.replace(backUrl);
    else history.goBack();
  };

  const { headerRef, sentinelRef } = useStickyHeader();

  return (
    <>
      {isDesktop && <div className="tw-mt-4" ref={sentinelRef} />}

      <div
        ref={headerRef}
        className={classnames(
          styles.heading,
          classes.heading,
          "tw-sticky tw-top-0 tw-py-6 tw-mx-12 max-md:tw-mx-0 tw-z-10 max-md:tw-static tw-transition-all tw-duration-300"
        )}
      >
        {(!isDesktop || showBackIcon) && (
          <ArrowBack className={styles.backIcon} onClick={onBackClick} />
        )}
        <div className="tw-flex tw-justify-between w-100 tw-items-center">
          <div className={classnames(styles.headingText, classes.headingText)}>
            {heading}
          </div>
          {headerRight}
        </div>
      </div>
    </>
  );
};
