import { logError } from "utils/error";
import { is_empty } from "features/Common/utils/common.utils";
import { useAdminPanelStore } from "../store/useAdminPanelStore";
import { getAdminPanelSettingsConfig } from "features/Account/utils/Account.utils";

export const useAdminPanelSettings = () => {
  const setAdminPanelConfig = useAdminPanelStore(
    (state) => state.setAdminPanelConfig
  );

  const adminPanelSettingsLoading = useAdminPanelStore(
    (state) => state.adminPanelSettingsLoading
  );

  const adminPanelSettingsConfig = useAdminPanelStore(
    (state) => state.adminPanelSettingsConfig
  );

  const setAdminPanelSettingsLoading = useAdminPanelStore(
    (state) => state.setAdminPanelSettingsLoading
  );
  const fetchAdminPanelSettings = async ({ key }) => {
    if (adminPanelSettingsLoading) return;
    if (!is_empty(adminPanelSettingsConfig[key])) return;
    try {
      setAdminPanelSettingsLoading(true);
      const response = await getAdminPanelSettingsConfig({ key });
      setAdminPanelConfig({
        [key]: response?.data,
      });
    } catch (error) {
      logError({
        error,
        occuredAt: "Error fetching admin settings config:",
        when: "calling fetchAdminPanelSettings",
      });
    } finally {
      setAdminPanelSettingsLoading(false);
    }
  };

  return { fetchAdminPanelSettings };
};
