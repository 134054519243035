import React from "react";
import styles from "./SignUpBonusActiveRefundsModalStyles.module.css";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { MailLink } from "features/Common/modules/MailLink/MailLink";
import { useSignUpBonusActiveRefundsModal } from "./utils/useSignUpBonusActiveRefundsModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

export const SignUpBonusActiveRefundsModal = withComponentLibraryTheme(() => {
  const { isSignUpBonusRefundsModal, closeSignUpBonusRefundsModal } =
    useSignUpBonusActiveRefundsModal();
  return (
    <ExlyModal
      title="Your Signup Bonus is Active"
      header="Your Signup Bonus is Active"
      primaryBtnText="Okay, Got it"
      onPrimaryBtnClick={closeSignUpBonusRefundsModal}
      open={isSignUpBonusRefundsModal}
      onClose={closeSignUpBonusRefundsModal}
      padded={false}
      showSecondaryBtn={false}
      modal_props={{
        modalPaperClassName: styles.modalPaper,
        footerClassName: styles.footer,
      }}
      primaryBtnFullWidth
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          You&apos;re currently earning <b>25% extra</b> on each transaction for
          the first 7 days as part of your signup bonus. Since your payouts
          include this bonus amount, enabling refunds at this stage isn&apos;t
          possible. If you still need to process refunds, please contact{" "}
          <MailLink />, and we&apos;ll assist you right away.
        </div>
      </div>
    </ExlyModal>
  );
});
