import React, { useEffect } from "react";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { useAdminPanelLayoutConfig } from "features/AdminPanel/modules/AdminPanelLayout/utils/useAdminPanelLayoutConfig";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { ADMIN_PANEL_SETTINGS_TITLE } from "features/AdminPanel/constants/AdminPanel.constants";
import { useAdminPanelSettings } from "features/AdminPanel/utils/useAdminPanelSettings";

export const MyAdminPanelWrapper = ({
  children,
  hideChildren,
  hideNav,
  hideHeader,
  classes = {},
  featureKey,
  headerTitle,
  headerRight,
  backUrl,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const adminPanelSettingsConfig = useAdminPanelStore(
    (state) => state.adminPanelSettingsConfig
  );

  const { fetchAdminPanelSettings } = useAdminPanelSettings();

  useEffect(() => {
    if (!hideNav) fetchAdminPanelSettings({ key: featureKey });
  }, [hideNav, featureKey]);

  useAdminPanelLayoutConfig({
    config: {
      headerTitle: headerTitle ??  ADMIN_PANEL_SETTINGS_TITLE,
      sideBarConfig: adminPanelSettingsConfig[featureKey],
      hideHeader: hideHeader ?? !isDesktop,
      hideNav: hideNav ?? !isDesktop,
      hideChildren,
      classes: classes,
      headerRight : headerRight,
      backUrl : backUrl
    },
  });

  return <>{children}</>;
};
