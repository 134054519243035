import constants from "constants/constants";
import { is_empty } from "features/Common/utils/common.utils";
import { getAuthData } from "utils/AuthUtil";

export const getProfilePicture = () => {
  const auth = getAuthData();

  const showdefaultPic =
    is_empty(auth.display_image) ||
    auth.display_image === constants.null_display_image;

  const profilePicture = showdefaultPic
    ? "../../assets/images/default-user.png"
    : auth.display_image;
  return profilePicture;
};
