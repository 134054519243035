import React from "react";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { SectionEnabledChip } from "features/AdminPanel/SectionEnabledChip/SectionEnabledChip";
import { SectionUnlockingSwitch } from "features/AdminPanel/modules/SectionUnlockingSwitch/SectionUnlockingSwitch";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { useLutModal } from "../../utils/useLutModal";
import { getSectionStates } from "features/AdminPanel/utils/AdminPanel.utils";

export const EnableLutSwitch = () => {
  const key = PAYMENT_OPTION_KEYS.LUT;
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[key] || {};
  const { value, status } = subSectionConfig;
  const { disableSwitch } = getSectionStates({
    status,
  });
  const { openLutModal } = useLutModal();
  return value ? (
    <SectionEnabledChip />
  ) : (
    <SectionUnlockingSwitch
      value={value}
      onChange={openLutModal}
      disabled={disableSwitch}
    />
  );
};
