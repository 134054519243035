import React from "react";
import { RefundsSubSection } from "../../RefundsSubSection/RefundsSubSection";
import { EnableLutSwitch } from "../../EnableLutModal/modules/EnableLutSwitch/EnableLutSwitch";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { DocumentUploadedNote } from "../../EnableLutModal/modules/DocumentUploadedNote/DocumentUploadedNote";

export const PAYMENT_OPTION_KEYS = {
  EMI: "emi",
  NO_COST_EMI: "no_cost_emi",
  CUSTOMER_REFUND: "customer_refund",
  INTERNATIONAL_PAYMENT: "international_payment",
  LUT: "lut",
};
export const PAYMENT_OPTIONS_CONFIG = [
  {
    title: "EMI Customer Payments",
    description:
      "Allow customers to split their payments into manageable monthly instalments. **Standard 4-5% rate applicable**.",
    subSectionConfig: [
      {
        key: PAYMENT_OPTION_KEYS.EMI,
        title: "Enable EMI payments",
        description:
          "Toggle on to start accepting EMI payments from customers.",
      },
    ],
  },
  {
    title: "No-cost EMI Customer Payments",
    description:
      "Offer EMI plans with zero interest or fees, a cost-effective payment option. **Standard 4-5% rate applicable**.",
    subSectionConfig: [
      {
        key: PAYMENT_OPTION_KEYS.NO_COST_EMI,
        title: "Enable No-cost EMI payments",
        description:
          "Toggle on to start accepting zero interest EMI payments from customers.",
      },
    ],
  },
  {
    title: "Customer Transaction Refunds",
    description:
      "Easily process refunds to ensure customer satisfaction with smooth transactions.",
    subSectionConfig: [
      {
        key: PAYMENT_OPTION_KEYS.CUSTOMER_REFUND,
        title: "Enable Refunds",
        description:
          "Toggle on to be able to process customer transaction refunds.",
        customRightJSX: <RefundsSubSection />,
        classes: { subSectionTextMainWrapperEnabledStyle: "d-none" },
      },
    ],
  },
  {
    title: "International Customer Payments",
    description:
      "Accept payments from international customers and expand globally.",
    subSectionConfig: [
      {
        key: PAYMENT_OPTION_KEYS.INTERNATIONAL_PAYMENT,
        title: "Collect international payments",
        description:
          "Toggle on to start accepting payments from international customers.",
      },
      {
        key: PAYMENT_OPTION_KEYS.LUT,
        title: "Exempt customers from GST",
        description:
          "Provide the GST certificate and Letter of Undertaking to disable GST application for international customers.",
        customRightJSX: <EnableLutSwitch />,
        lockedStatesArr: [FEATURE_STATUS.PLAN_LOCKED],
        customFooter: (
          <DocumentUploadedNote text="Documents have been uploaded successfully. We’ll notify you once it has been activated." />
        ),
      },
    ],
  },
];
