export const GENERATE_OTP = "payments/generate/generic/otp";
export const VERIFY_OTP = "payments/verify/generic/otp";

// Contact form api
export const GET_CONTACT_FORM_QUESTIONS_LIST_API_ENDPOINT =
  "users/adminpanel/setting/getintouch/get";
export const CONTACT_FORM_QUESTIONS_REORDERING_API_ENDPOINT =
  "users/adminpanel/setting/getintouch/reorder";
export const CONTACT_FORM_DELETE_DELETE_QUESTION_API_ENDPOINT =
  "users/adminpanel/setting/getintouch/delete";
export const CONTACT_FORM_UPDATE_QUESTION_API_ENDPOINT =
  "users/adminpanel/setting/getintouch/update";
export const ADD_CONTACT_FORM_QUESTION_API_ENDPOINT =
  "users/adminpanel/setting/getintouch/add";
