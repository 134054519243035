import { useEffect, useRef } from "react";

export const useStickyHeader = () => {
  const headerRef = useRef(null);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current;
    const sentinel = sentinelRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          header.classList.add("tw-mx-12", "tw-shadow-none");
          header.classList.remove("tw-px-12", "tw-shadow-md");
        } else {
          header.classList.add("tw-px-12", "tw-shadow-md");
          header.classList.remove("tw-mx-12", "tw-shadow-none");
        }
      },
      {
        threshold: 0,
      }
    );

    if (sentinel) observer.observe(sentinel);

    return () => {
      if (sentinel) observer.unobserve(sentinel);
    };
  }, []);

  return { headerRef, sentinelRef };
};
