import React from "react";
import { useHistory } from "react-router-dom";
import { Header } from "common/Components/Header";
import { SideBar } from "features/AdminPanel/modules/SideBar/SideBar";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import styles from "./AdminPanelLayoutStyles.module.css";
import classnames from "classnames";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

export const AdminPanelLayout = withComponentLibraryTheme(({ children }) => {
  const history = useHistory();

  const adminPanelLayoutConfig = useAdminPanelStore(
    (state) => state.adminPanelLayoutConfig
  );

  const {
    hideNav,
    hideChildren,
    headerTitle,
    classes,
    hideHeader,
    headerRight,
    backUrl,
  } = adminPanelLayoutConfig;

  const onClose = () => {
    if (backUrl) history.replace(backUrl);
    else history.goBack();
  };

  return (
    <div>
      {!hideHeader && (
        <Header
          title={headerTitle}
          onBackClick={onClose}
          classNames={{
            container:
              classes.container ?? headerRight ? "px-3 top-0" : "px-4 top-0",
            title: "!tw-text-[18px] !tw-font-bold",
          }}
          right={headerRight}
        />
      )}
      <div className="d-flex">
        {!hideNav && <SideBar />}
        <div
          className={classnames(
            styles.childrenComponentStyle,
            hideChildren && styles.hide,
            classes.childrenComponentStyle
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
});
