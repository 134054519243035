import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { GENERATE_OTP, VERIFY_OTP } from "../Account.api";
import { dataProvider } from "data";
import { GET_ADMIN_PANEL_SETTINGS_CONFIG_API_ENDPOINT } from "features/AdminPanel/constants/AdminPanel.api";

export const verifyOTP = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    VERIFY_OTP,
    apiMethods.POST,
    payload
  );
  if (!isRequestSuccessful(status))
    throw new Error(`Error verifying OTP: ${data} ${status}`);
  return data;
};

export const generateOTP = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    GENERATE_OTP,
    apiMethods.POST,
    payload
  );
  if (!isRequestSuccessful(status))
    throw new Error(`Error generating OTP: ${data} ${status}`);
  return data;
};

export const getAdminPanelSettingsConfig = async ({ key }) => {
  return await dataProvider.custom_request(
    `${GET_ADMIN_PANEL_SETTINGS_CONFIG_API_ENDPOINT}/${key}`,
    apiMethods.GET
  );
};

export const getSectionConfig = async ({ key, featureKey }) => {
  return await dataProvider.custom_request(
    `${GET_ADMIN_PANEL_SETTINGS_CONFIG_API_ENDPOINT}/${featureKey}/${key}`,
    apiMethods.GET
  );
};
